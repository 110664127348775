import React, { useState, useEffect, useRef } from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Slider from 'react-slick';
import img02 from './images/android-tv-hbanner.webp';
import Okgoogle from './images/okgoogle.webp'
import Icon01 from './images/ad04.webp'
import Icon02 from './images/ad05.webp'
import Icon03 from './images/ad06.webp'
import Icon04 from './images/ad07.webp'
import Icon05 from './images/ad08.webp'
import Icon06 from './images/ad09.webp'
import setupbox from './images/setupbox.webp'
import Ftr01 from './images/adfeature01.webp'
import Ftr02 from './images/adfeature02.webp'
import Ftr03 from './images/adfeature03.webp'
import Ftrl01 from './images/4khd.webp'
import Ftrl02 from './images/dublyvision.webp'
import Ftrl03 from './images/dubly-audio.webp'
import Channels from './images/channels.webp'
import Playstore from './images/playstore.webp'
import Okgooglecurve from './images/okgooglecurve.webp'
import Voicesearch from './images/voicesearch.webp'
import Screenshare from './images/screenshare.webp'
import Screencast from './images/screencast.webp'
import Ultra4k from './images/ultra4k.webp'
import Ultra4khd from './images/4khd.webp'
import { Link, NavLink } from 'react-router-dom';
import Plan_bg from "./images/Plan_Bg.webp"
import axios from 'axios';


export const Androidtv = () => {

    var decodeHTML = function (html) {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    function createMarkup(content) {
        return { __html: decodeHTML(content) };
    }

    var settings = {
        dots: true,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: false,
        cssEase: "linear"
    };

    const [pagedata, setPagedata] = useState([]);
    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
            const response = await axios.get(window.weburl + `api/plans.php?catID=5`)
            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            mounted = false;
        };
    }, []);

    console.log(pagedata);
    if (pagedata.length === 0) {
        console.log('no data');
        // return null;
    }

    const [user_mobile, setusermobile] = useState();
    const [plans, setPlans] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const ref_mobile = useRef(null);
    const ref_plan = useRef(null);

    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        axios.get(window.weburl + 'api/get_plans.php?cid=5')
            .then(response => setPlans(response.data))
            .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        axios.get(window.weburl + 'api/get_states.php')
            .then(response => setStates(response.data))
            .catch(error => console.log(error));
    }, []);

    function getCities(state) {
        axios.get(window.weburl + `api/get_cities.php?sid=${state}`)
            .then(response => setCities(response.data))
            .catch(error => console.log(error));
    }

    const handledisable = () => {
        if (isValidPhone(user_mobile) && (user_mobile)) {
            const element = document.getElementById('submit_button_text');
            element.classList.remove('disabled');
            setIsDisabled(false);
        } else {
            const element = document.getElementById('submit_button_text');
            element.classList.add('disabled');
            setIsDisabled(true);
        }
    };

    function isValidPhone(phone) {
        const regex = /^[6-9]\d{9}$/gi;
        return regex.test(phone);
    }

    const handleSubmit = () => {
        if (!isValidPhone(user_mobile)) {
            alert("Please enter Mobile number");
            ref_mobile.current.focus();
            return false;
        }
        if(!selectedPlan) {
            alert("Please select plan");
            ref_plan.current.focus();
            return false;
        }
        else {
            document.getElementById("submit_button_text").innerText='SUBMITTING...';
            const url = window.weburl + "api/insert_hook.php";
            let fData = new FormData();
            fData.append('user_phone', user_mobile);
            fData.append('catid', '5');
            fData.append('pid', selectedPlan);
            fData.append('user_state', selectedState);
            fData.append('user_city', selectedCity);
            fData.append('td_name', 'androidtv');
            fData.append('utm_url', window.location.href);
            axios.post(url, fData)
                .then(response => alert(response.data))
                .catch(error => alert(error));
            document.getElementById("submit_button_text").innerText='SUBMIT';
            document.getElementById("user_mobile").value='';
            document.getElementById("selectedPlan").value='';
            document.getElementById("pid").value='';
            document.getElementById("user_state").value='';
            document.getElementById("user_city").value='';
        }
    }

    return (
        <>
            <Header />
            <section id='banner' className='p-0' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                <div className='banner mngslide'>
                    <div className='container'>
                        <div className='row'>
                            <Slider {...settings}>
                                <div>
                                    <div className='sliderrow'>
                                        <div className='slideimg'>
                                            <img src={img02} alt='Banner Netplus' />
                                        </div>
                                        <div className='slidecont'>
                                            <div className='circle01'>
                                                <div className='mngcircle'>
                                                    <div className='circlimg'>
                                                        <h2>Break the <br />barrier!</h2>
                                                    </div>
                                                    <div className='listpoint'>
                                                        <p>Fastway+ Ultra 4K TV Box with HDR Streaming Chromecast built-in</p>
                                                    </div>
                                                    <div className='prize'>
                                                        <div className='googleicon'>
                                                            <img src={Okgoogle} alt='' />
                                                        </div>
                                                        <div className='pack'>
                                                            <h5>now at <strong><i className='finr'></i> 2500/-</strong></h5>
                                                        </div>
                                                    </div>
                                                    <div className='booknow'>
                                                        <NavLink to='/book-now/SEdwcDFGd1VjNVB4WFhvL2xSa0FNZz09'>Get Now</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='circle02'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <section id='filter' className='p-0'>
                <div className='filter'>
                    <div className="container">
                        <div className="row">
                            <div className="web-container">
                                <div className="footerform" data-aos="fade-up" data-aos-offset="10" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <div className='heading'>
                                        <h4>Get it today</h4>
                                    </div>
                                    <div className="form">
                                            <ul>
                                                <li>
                                                <input type="text" name="user_mobile" id="user_mobile" placeholder='Mobile number*' pattern="[a-zA-Z\s]+" value={user_mobile} onChange={(e) => { setusermobile(e.target.value); }} onKeyUp={handledisable} ref={ref_mobile} />
                                                </li>
                                                <li><select name="selectedPlan" id="selectedPlan" value={selectedPlan} onChange={event => { setSelectedPlan(event.target.value); }} ref={ref_plan}>
                                                    <option value="">Select Plan</option>
                                                    {plans.map(plan => (
                                                        <option key={plan.sno} value={plan.sno} dangerouslySetInnerHTML={createMarkup(plan.pname + ' ' + plan.ptitle)}></option>
                                                    ))}
                                                </select></li>
                                                <li><select name="selectedState" id="selectedState" value={selectedState} onChange={event => { setSelectedState(event.target.value); getCities(event.target.value); }}>
                                                    <option value="">Select Region</option>
                                                    {states.map(state => (
                                                        <option key={state.stateid} value={state.stateid}>{state.sname}</option>
                                                    ))}
                                                </select></li>
                                                <li><select name="selectedCity" id="selectedCity" value={selectedCity} onChange={event => setSelectedCity(event.target.value)}>
                                                    <option value="">Select City</option>
                                                    {cities.map(city => (
                                                        <option key={city.cityid} value={city.cityid}>{city.cityname}</option>
                                                    ))}
                                                </select></li>
                                                <li className='buttnprop'><input type="submit" value="" id="submit_button" disabled={isDisabled} onClick={handleSubmit} /><span id="submit_button_text" className='disabled'>SUBMIT</span></li>
                                            </ul>      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='androidpoints'>
                <div className='androidpoints'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='heading' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <h4>Android TV Bundle</h4>
                                </div>
                                <div className='gradienticons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <ul>
                                        <li><span><img src={Icon01} alt='' /></span><p>450+ Channels</p></li>
                                        <li><span><img src={Icon02} alt='' /></span><p>4K Ultra HD</p></li>
                                        <li><span><img src={Icon03} alt='' /></span><p>Dolby Vision</p></li>
                                        <li><span><img src={Icon04} alt='' /></span><p>Dolby Audio</p></li>
                                        <li><span><img src={Icon05} alt='' /></span><p>Dual-Band</p></li>
                                        <li><span><img src={Icon06} alt='' /></span><p>Micro SD XC</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section id='androidtv' className='pt-0'>
                <div className='androidtv'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='androidtvrow'>
                                    <div className='androidtvrow3' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <img src={setupbox} alt='SetUp Box' />
                                    </div>
                                    <div className='androidbox7'>
                                        <div className='andoidcont' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <h4>A Smart way to watch</h4>
                                            <p>Welcome to a smarter way to watch your favourite content. Enjoy watching TV with the Google featured Fastway Ultra 4K Android TV Box.</p>
                                            <div className='buttnprop'>
                                                <Link to='/book-now/SEdwcDFGd1VjNVB4WFhvL2xSa0FNZz09'><span>Get Now</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='androidfeature' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <ul>
                                        <li><span><img src={Ftr01} alt='' /><img src={Ftrl01} alt='' /></span></li>
                                        <li><span><img src={Ftr02} alt='' /><img src={Ftrl02} alt='' /></span></li>
                                        <li><span><img src={Ftr03} alt='' /><img src={Ftrl03} alt='' /></span></li>
                                    </ul>
                                </div>

                                <div className='planbanner'>
                                    <div className='plancont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <h4>Plug and Play, Out Of The Way</h4>
                                        <p>connect and access content from all your favourite devices. </p>
                                    </div>
                                </div>

                                <div className='clearfix'></div>
                                {pagedata.data ?
                                    <div className='planstab' >
                                        <div className='title m-0'>
                                            <span>Entertainment Plans</span>
                                        </div>


                                        <div className='planstabpanel'>
                                            <div className='androidtv' id='plans'>
                                                <div className='bundlestn'>
                                                    {pagedata.data.map(getData => {
                                                        const newValues = getData.features.split('|_');
                                                        return <div className='bundlebox'>
                                                            <h4 dangerouslySetInnerHTML={createMarkup(getData.pname)}></h4>
                                                            <div className='image_plans_div'>
                                                                <div className="image_plan">
                                                                    <img src={Plan_bg} alt="Plan Image" />
                                                                </div>
                                                                <p dangerouslySetInnerHTML={createMarkup(getData.ptitle)}></p>
                                                            </div>

                                                            {getData.features ?
                                                                <ul>
                                                                    {newValues.map(featuresValue => (
                                                                        <li dangerouslySetInnerHTML={createMarkup(featuresValue)}></li>
                                                                    ))}
                                                                </ul>
                                                                : null}
                                                            <div className='price'>
                                                                <h6>{getData.price}</h6>
                                                            </div>


                                                            <div className='buttnprop'>
                                                                <Link to={'/book-now/' + getData.plan_id}><span>Book Now </span></Link>
                                                            </div>
                                                        </div>
                                                    })}



                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='androidbox' className='pt-0'>
                <div className='androidbox'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='squarecont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <ul>
                                        <li><img src={Playstore} alt='play store' /><p>Apps you'll love on your TV</p><img src={Channels} alt='play store' /><p>5000+ Latest Android Apps<br/>Access anytime, anywhere</p></li>
                                        <li><img src={Okgooglecurve} alt='Google' /><p>Google assistant on tv</p><img src={Voicesearch} alt='play store' /><p>Built-in Google Assistant<br/>Remote with quicklyfind mic button</p></li>
                                        <li><img src={Screenshare} alt='play store' /><p>Share mobile screen on tv</p><img src={Screencast} alt='play store' /><p>Display your favorite content on the big screen<br/>Easily navigate through various streaming apps</p></li>
                                        <li><img src={Ultra4khd} alt='play store' /><p>Stunning ultra 4k picture quality</p><img src={Ultra4k} alt='play store' /><p>4K resolution<br/>Dolby Vision</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Contactform /> */}
            <Footer />
        </>
    )
}

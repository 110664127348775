import React from 'react'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/contact-us-banner.webp'

export const Contactus = () => {
        return (
                <>
                        <Header />
                        <section id='banner' className='p-0'>
                                <div className='banner' style={{ backgroundImage: 'url(' + Bgimage + ')' }}>
                                </div>
                        </section>
                        <section id='contactpage' className='p-0'>
                                <div className='contactpage'>
                                        <div className='container'>
                                                <div className='row'>
                                                        <div className='web-container'>
                                                                <div className='title'>
                                                                        <span>Contact us</span>
                                                                </div>
                                                                <div className='contactoverview'>
                                                                        <div className='contactbox'>
                                                                                <h3>RETAIL</h3>
                                                                                <ul>
                                                                                        <li><span>Call Us</span><p><a href='tel:+917087570875'>+91-70875-70875</a></p></li>
                                                                                        <li><span>Email</span><p><a href='mailto:customercare@netplus.co.in'>customercare@netplus.co.in</a><a href='mailto:L2-customercare@netplus.co.in'>L2-customercare@netplus.co.in</a></p></li>

                                                                                </ul>
                                                                        </div>
                                                                        <div className='contactbox'>
                                                                                <h3>ENTERPRISE </h3>
                                                                                <ul>
                                                                                        <li><span>Call Us</span><p><a href='tel:+917833878338'>+91-78338-78338</a></p></li>
                                                                                        <li><span>Email</span><p><a href='mailto:enterprisecare@netplus.co.in'>enterprisecare@netplus.co.in</a>
                                                                                        </p></li>
                                                                                </ul>
                                                                        </div>
                                                                </div>
                                                                <div className='contactoverview'>
                                                                        <div className='contactbox'>
                                                                                <h3>Head Office</h3>
                                                                                <div className='office'>
                                                                                        <div className='officebox'>
                                                                                                <h4>Ludhiana</h4>
                                                                                                <ul>
                                                                                                        <li>Address : Netplus Broadband Services Pvt Ltd. 5th floor The Grand Walk Mall, H Block Ludhiana 141012</li>
                                                                                                        <li>Telephone : <a href='tel:+917087570875'>+91-70875-70875</a></li>
                                                                                                        <li><span><a href='https://goo.gl/maps/xsS1au4KsvQBJroX8' target='_blank'>View Google Map</a></span></li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className='contactbox'>
                                                                                <h3>Zonal Offices </h3>
                                                                                <div className='office'>
                                                                                        <div className='officebox'>
                                                                                                <h4>Amritsar</h4>
                                                                                                <ul>
                                                                                                        <li>Address : 1st Floor Alpha International City G.T Road Daburji Amritsar, 143022.</li>
                                                                                                        <li>Email : <a href='mailto:customercare@netplus.co.in'>customercare@netplus.co.in</a></li>
                                                                                                        <li>Telephone : <a href='tel:+917087570875'>+91-70875-70875</a></li>
                                                                                                        <li><span><a href='https://goo.gl/maps/Q9VjjQnLyfu7kZ1W7' target='_blank'>View Google Map</a></span></li>
                                                                                                </ul>
                                                                                        </div>
                                                                                        <div className='officebox'>
                                                                                                <h4>Bathinda</h4>
                                                                                                <ul>
                                                                                                        <li>Address : B-77 Teshwar Complex near Hazzi Rattan Chowk , Above IDBI Bank BHATINDA</li>
                                                                                                        <li>Email : <a href='mailto:customercare@netplus.co.in'>customercare@netplus.co.in</a></li>
                                                                                                        <li>Telephone : <a href='tel:+917087570875'>+91-70875-70875</a></li>
                                                                                                        <li><span><a href='https://goo.gl/maps/2pK7WXjz77wLj6hJA' target='_blank'>View Google Map</a></span></li>
                                                                                                </ul>
                                                                                        </div>
                                                                                        <div className='officebox'>
                                                                                                <h4>Chandigarh</h4>
                                                                                                <ul>
                                                                                                        <li>Address : C/o Daily Post, Plot No. 17 1st FLOOR, IND AREA, PHASE-I Chandigarh</li>
                                                                                                        <li>Email : <a href='mailto:customercare@netplus.co.in'>customercare@netplus.co.in</a></li>
                                                                                                        <li>Telephone : <a href='tel:+917087570875'>+91-70875-70875</a></li>
                                                                                                        <li><span><a href='https://goo.gl/maps/4SkwnjC7E22a7GnF7' target='_blank'>View Google Map</a></span></li>
                                                                                                </ul>
                                                                                        </div>
                                                                                        <div className='officebox'>
                                                                                                <h4>Jalandhar</h4>
                                                                                                <ul>
                                                                                                        <li>Address : C/o Fastway Transmission (P) Ltd., 6tH FLOOR THE GRAND MALL BUILDING NEAR RADISSON HOTEL GT ROAD Jalandhar 144201</li>
                                                                                                        <li>Email : <a href='mailto:customercare@netplus.co.in'>customercare@netplus.co.in</a></li>
                                                                                                        <li>Telephone : <a href='tel:+917087570875'>+91-70875-70875</a></li>
                                                                                                        <li><span><a href='https://www.google.com/maps?ll=31.330417,75.598135&z=13&t=m&hl=en-US&gl=US&mapclient=embed&q=Netplus+Broadband,+The+grand+Mall+Building+Near+raddison+hotel+gt+road,Jalandhar+punjab+144201' target='_blank'>View Google Map</a></span></li>
                                                                                                </ul>
                                                                                        </div>
                                                                                        <div className='officebox'>
                                                                                                <h4>Ludhiana</h4>
                                                                                                <ul>
                                                                                                        <li>Address : Skyma Infra pvt ltd.Canal Road Adjoining gurunank homeopathic College</li>
                                                                                                        <li>Email : <a href='mailto:customercare@netplus.co.in'>customercare@netplus.co.in</a></li>
                                                                                                        <li>Telephone : <a href='tel:+917087570875'>+91-70875-70875</a></li>
                                                                                                        <li><span><a href='https://goo.gl/maps/MUgR74QJBBY4HxXB9' target='_blank'>View Google Map</a></span></li>
                                                                                                </ul>
                                                                                        </div>
                                                                                        <div className='officebox'>
                                                                                                <h4>Patiala</h4>
                                                                                                <ul>
                                                                                                        <li>Address : SCO 144, 3rd FLOOR, CHOTI BARADARI, Patiala</li>
                                                                                                        <li>Email : <a href='mailto:customercare@netplus.co.in'>customercare@netplus.co.in</a></li>
                                                                                                        <li>Telephone : <a href='tel:+917087570875'>+91-70875-70875</a></li>
                                                                                                        <li><span><a href='https://goo.gl/maps/MUgR74QJBBY4HxXB9' target='_blank'>View Google Map</a></span></li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <Contactform />
                                        <Footer />
                                </div>
                        </section>
                </>
        )
}
